<template>
  <header class="clearfix">
    <div class="container">
      <div class="logo-wrapper">
        <h1 id="logo"><a href="index.html">Stamford</a></h1>
        <!--<div class="tagline"><span>digital. <br />
          photography.</span></div>-->
      </div>
      <div id="menu-button">
        <div class="centralizer">
          <div class="cursor"><span class="hide">Menu</span>
            <div id="nav-button"> <span class="nav-bar"></span> <span class="nav-bar"></span> <span class="nav-bar"></span> </div>
            <div id="menu-close-button">&times;</div>
          </div>
        </div>
      </div>
      <!-- start main nav -->
      <nav id="main-nav">
        <ul id="options" class="option-set clearfix" data-option-key="filter">
          <li class="selected"> <a href="#home">Accueil</a> </li>
          <li> <a href="#portfolio">Portfolio</a></li>
          <li> <a href="#about">
            A propos de nous</a> </li>
          <li> <a href="#services">Services</a> </li>
          <!--<li> <a href="#blog">Blog</a> </li>-->
          <li> <a href="#contact">Contact</a> </li>
        </ul>
        <!--<div class="social-links">
          <ul class="social-list clearfix">
            <li> <a href="#" class="pinterest"></a> </li>
            <li> <a href="#" class="twitter"></a> </li>
            <li> <a href="#" class="gplus"></a> </li>
            <li> <a href="#" class="facebook"></a> </li>
          </ul>
        </div>-->
      </nav>
      <!-- end main nav -->
    </div>
  </header>


</template>

<script>

export default {
  name: 'Header',
}
</script>
