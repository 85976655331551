<template>
  <div class="homepage h-full">
    <Preloader/>
    <Header/>
    <Home/>
    <Footer/>

  </div>
</template>

<script>
import Home from './views/Home.vue'
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Preloader from "@/components/Preloader";

export default {
  name: 'App',
  components: {
    Preloader,
    Home,
    Header,
    Footer
  },
}
</script>
