<template>
<footer class="clearfix">
<div class="container">
  <p class="alignleft">© 2023, Rainbow Ant Studio. All Rights Reserved.</p>
  <!--<p class="alignright">285 Lexington Ave, New York, NY <span class="padding">&middot;</span> (845)&nbsp;123-4567 <span class="padding">&middot;</span> <a href="mailto:info@stamford.com" title="Write Email">hello@stamford.com</a> </p>-->
</div>
</footer>
</template>

<script>

export default {
  name: 'Header',
}
</script>
