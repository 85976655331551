<template>
    <div id="wrap">
        <div class="content-wrapper">
            <div id="content">
                <div class="container clearfix">
                    <div id="container" class="clearfix">
                        <div class="element clearfix col3-3 home">
                            <div class="col2-3">
                                <div class="flexslider">
                                    <div class="images">
                                        <ul class="slides">
                                            <li><img src="images/slider_1-1.jpg" alt=""/></li>
                                            <li><img src="images/slider_1-2.jpg" alt=""/></li>
                                            <li><img src="images/slider_1-3.jpg" alt=""/></li>
                                            <li><img src="images/slider_1-4.jpg" alt=""/></li>
                                            <li><img src="images/slider_1-5.jpg" alt=""/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col1-3 white white-right">
                                <h2>Rainbow Ant Studio</h2>
                                <p>Nous sommes un studio qui se concentre sur la création de produits et de solutions
                                    immersives pour les clients en utilisant les technologies de la réalité virtuelle,
                                    de la réalité augmentée, de la modélisation 3D, de l'animation 3D et du
                                    développement de jeux vidéo.</p>
                                <div class="bottom">
                                    <p><a href="#portfolio" title="">Portfolio <span class="arrow">→</span></a></p>
                                </div>
                            </div>
                        </div>
                        <div class="element clearfix col1-3 home about">
                            <div class="images bottom"><img src="images/image02.jpg" alt=""/></div>
                            <div class="white white-top">
                                <h2>Secteurs d'activité</h2>
                                <p>Nous sommes spécialisés dans les secteurs de la muséographie, de l'architecture et de
                                    la présentation de produits.</p>
                            </div>
                        </div>
                        <div class="element clearfix col1-3 portfolio menu">
                            <div class="images"><img src="images/image02.jpg" alt=""/></div>
                            <div class="white white-bottom">
                                <h2>Choisir une catégorie</h2>
                                <p>Parcourez notre portfolio :</p>
                                <ul class="unordered-list">
                                    <li><a href="#3dmodeling,.menu">3D Modeling<span class="arrow">→</span></a></li>
                                    <li><a href="#3drendering,.menu">3D Rendering<span class="arrow">→</span></a></li>
                                    <!--<li><a href="#norway,.menu">Norway was awesome <span class="arrow">→</span></a></li>-->
                                    <li><a href="#portfolio">Afficher tout <span class="arrow">→</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="element clearfix col1-3 home portfolio 3dmodeling 3drendering">
                            <!--<a target="_blank" href="https://fastidious-sprinkles-0b6dca.netlify.app/360">
                              <button class="btn"><img src="images/icons/360.png"></button>
                            </a>-->
                            <a href="images/houses/house_01.jpg" data-title="Image 1" class="popup"
                               data-fancybox-group="group2">
                                <div class="images"><img src="images/houses/house_thumbnail.jpg" alt=""/>

                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Home design and rendering</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling, 3D rendering</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/houses/house_02.jpg" data-title="Image 2" class="popup hidden-links"
                               data-fancybox-group="group2"></a>
                            <a href="images/houses/house_03.jpg" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_04.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_05.jpg" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_06.jpg" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_07.jpg" data-title="Image 7"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_08.jpg" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_09.jpg" data-title="Image 9"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                            <a href="images/houses/house_10.jpg" data-title="Image 10"
                               class="popup hidden-links" data-fancybox-group="group2"></a>
                        </div>
                        <div class="element clearfix col1-3 half portfolio 3dmodeling 3drendering">
                            <a href="images/library/library_01_01.jpg" data-title="Image 1" class="popup"
                               data-fancybox-group="group5">
                                <div class="images"><img src="images/library/library_thumbnail.jpg" alt=""/>
                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Home design and rendering</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling, 3D rendering</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/library/library_01_02.jpg" data-title="Image 2" class="popup hidden-links"
                               data-fancybox-group="group5"></a>
                            <a href="images/library/library_01_03.jpg" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group5"></a>
                            <a href="images/library/library_01_04.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group5"></a>
                            <a href="images/library/library_02_01.jpg" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group5"></a>
                            <a href="images/library/library_02_02.jpg" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group5"></a>
                            <a href="images/library/library_02_03.jpg" data-title="Image 7"
                               class="popup hidden-links" data-fancybox-group="group5"></a>
                            <a href="images/library/library_02_04.jpg" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group5"></a>

                        </div>
                        <div class="element clearfix col1-3 home portfolio 3dmodeling">
                            <a href="images/farm/buildings_image.png" data-title="Image 1" class="popup"
                               data-fancybox-group="group1">
                                <div class="images"><img src="images/farm/farm_thumbnail.png" alt=""/>
                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Low Poly Farm pack</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/farm/farm_house.png" data-title="Image 2" class="popup hidden-links"
                               data-fancybox-group="group1"></a>
                            <a href="images/farm/vehicles_image.png" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                            <a href="images/farm/buildings.png" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                            <a href="images/farm/props.png" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                            <a href="images/farm/trees.png" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                            <a href="images/farm/vegetables.png" data-title="Image 7"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                            <a href="images/farm/vehicles.png" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group1"></a>
                        </div>

                        <div class="element clearfix col1-3 home portfolio 3dmodeling 3drendering">
                            <a href="images/jewelry/clock_01.jpg" data-title="Image 1" class="popup"
                               data-fancybox-group="group3">
                                <div class="images"><img src="images/jewelry/jewelry_thumbnail.jpg" alt=""/>
                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Props Design</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling, 3D Rendering</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/jewelry/clock_02.jpg" data-title="Image 2" class="popup hidden-links"
                               data-fancybox-group="group3"></a>
                            <a href="images/jewelry/necklace_01.jpg" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group3"></a>
                            <a href="images/jewelry/necklace_02.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group3"></a>
                            <a href="images/jewelry/crown_01.jpg" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group3"></a>
                            <a href="images/jewelry/crown_02.jpg" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group3"></a>

                        </div>
                        <div class="element blog-teaser clearfix col1-3 home">
                            <div class="images"><img src="images/image02.jpg" alt=""/></div>
                            <div class="white white-bottom">
                                <h2>Services</h2>
                                <ul class="unordered-list">
                                    <li> Modélisation, Animation 2D/3D</li>
                                    <li> Développement Mobile</li>
                                    <li> Développement Web</li>
                                    <li> Développement Réalité virtuelle</li>
                                    <li> Développement Réalité Augmenté</li>
                                    <li> Développement électronique</li>
                                </ul>
                            </div>
                        </div>
                        <div class="element blog-teaser clearfix col3-3 half blog"><a href="post.html">
                            <div class="col2-3 half">
                                <div class="images"><img src="images/image30.jpg" alt=""/></div>
                            </div>
                            <div class="col1-3 white white-right half">
                                <p class="small">January 3, 2015</p>
                                <h3>We Appreciate Any Kind of Feedback</h3>
                                <div class="bottom">
                                    <p>Read more <span class="arrow">→</span></p>
                                </div>
                            </div>
                        </a></div>
                        <div class="element blog-teaser clearfix col3-3 half blog"><a href="post.html">
                            <div class="col2-3 half alignright">
                                <div class="images"><img src="images/image20.jpg" alt=""/></div>
                            </div>
                            <div class="col1-3 white white-left half">
                                <p class="small">January 1, 2015</p>
                                <h3>Post With a Quote</h3>
                                <div class="bottom">
                                    <p>Read more <span class="arrow">→</span></p>
                                </div>
                            </div>
                        </a></div>
                        <div class="element clearfix col1-3 home portfolio 3dmodeling 3drendering">
                            <a href="images/environment/Forest_path_01.png" data-title="Image 1" class="popup"
                               data-fancybox-group="group4">
                                <div class="images"><img src="images/environment/environment_thumbnail.png" alt=""/>
                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Environment Design</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling, 3D Rendering</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/environment/Forest_path_02.png" data-title="Image 2"
                               class="popup hidden-links"
                               data-fancybox-group="group4"></a>
                            <a href="images/environment/Old_house_01.png" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group4"></a>
                            <a href="images/environment/Old_house_02.png" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group4"></a>
                            <a href="images/environment/Old_house_03.png" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group4"></a>
                            <a href="images/environment/Old_house_04.png" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group4"></a>
                            <a href="images/environment/Quarry_01.jpg" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group4"></a>
                            <a href="images/environment/Quarry_02.jpg" data-title="Image 7"
                               class="popup hidden-links" data-fancybox-group="group4"></a>

                        </div>
                        <div class="element clearfix col1-3 half portfolio 3dmodeling 3drendering">
                            <a href="images/old_buildings/old_building_01_01.jpg" data-title="Image 1" class="popup"
                               data-fancybox-group="group6">
                                <div class="images"><img src="images/old_buildings/old_building_thumbnail.jpg" alt=""/>
                                    <div class="hidden-infos">
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="title">
                                        <div class="title-wrap">
                                            <h3>Old Building</h3>
                                        </div>
                                    </div>
                                    <div class="subtitle">
                                        <div class="subtitle-wrap">
                                            <p>3D Modeling, 3D rendering</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="images/old_buildings/old_building_01_02.jpg" data-title="Image 2"
                               class="popup hidden-links"
                               data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_01_03.jpg" data-title="Image 3"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_01_04.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_01_05.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_01_06.jpg" data-title="Image 4"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_01.jpg" data-title="Image 5"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_02.jpg" data-title="Image 6"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_03.jpg" data-title="Image 7"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_04.jpg" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_05.jpg" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group6"></a>
                            <a href="images/old_buildings/old_building_02_06.jpg" data-title="Image 8"
                               class="popup hidden-links" data-fancybox-group="group6"></a>

                        </div>
                        <div class="element blog-teaser clearfix col1-3 home contact">
                            <div class="images"><img src="images/image02.jpg" alt=""/></div>
                            <div class="white white-bottom">
                                <h2>Contactez Nous</h2>
                                <!--<p>285 Lexington Ave<br/>
                                  New York, NY</p>-->
                                <p> Email: <a href="mailto:contact@rainbowantstudio.com" title="">contact@rainbowantstudio.com</a><br/>
                                    <!--Phone: (845) 123-4567--></p>
                            </div>
                        </div>
                        <div class="element blog-teaser clearfix col3-3 half blog"><a href="post.html">
                            <div class="col2-3 half">
                                <div class="images"><img src="images/image50.jpg" alt=""/></div>
                            </div>
                            <div class="col1-3 white white-right half">
                                <p class="small">December 23, 2014</p>
                                <h3>Post With an Awesome Slideshow</h3>
                                <div class="bottom">
                                    <p>Read more <span class="arrow">→</span></p>
                                </div>
                            </div>
                        </a></div>
                        <div class="element clearfix col2-3 home about"><a
                                href="https://player.vimeo.com/video/796711574?h=04b68691bf;portrait=0&amp;color=ffffff=0&amp;autoplay=1"
                                class="video-popup" data-title="Our Reel">
                            <div class="images">
                                <div class="share-box">
                                    <div>
                                        <ul class="social-list clearfix">
                                            <li><span class="vimeo"></span></li>
                                        </ul>
                                    </div>
                                </div>
                                <img src="images/image40.jpg" alt=""/>
                                <div class="hidden-infos">
                                    <div class="overlay"></div>
                                </div>
                                <div class="title">
                                    <div class="title-wrap">
                                        <h3>Watch my Reel</h3>
                                    </div>
                                </div>
                                <div class="subtitle">
                                    <div class="subtitle-wrap">
                                        <p>Vimeo</p>
                                    </div>
                                </div>
                            </div>
                        </a></div>
                        <div class="element clearfix col1-3 services">
                            <div class="col1-3">
                                <div class="images"><img src="images/operagarnier/operagarnier_thumbnail.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div class="element clearfix col1-3 services">
                            <div class="images"><img src="images/image02.jpg" alt=""/></div>
                            <div class="white white-bottom">
                                <h2>Services</h2>
                                <ul class="unordered-list">
                                    <li> Modélisation, Animation 2D/3D</li>
                                    <li> Développement Mobile</li>
                                    <li> Développement Web</li>
                                    <li> Développement Réalité virtuelle</li>
                                    <li> Développement Réalité Augmenté</li>
                                    <li> Développement électronique</li>
                                </ul>
                            </div>
                        </div>
                        <div class="element clearfix col1-3 services">
                            <div class="col1-3">
                                <div class="images"><img src="images/farm/farm_thumbnail.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div class="element clearfix col2-3 contact">
                            <div class="col1-3">
                                <div class="images"><img src="images/gallery/gallery_thumbnail.jpg" alt=""/></div>
                            </div>
                            <div class="col1-3 white white-right">
                                <h2>Envoyez nous un Email</h2>
                                <form class="form-part"
                                      method="post"
                                      name="contactform"
                                      id="contactform">
                                    <input name="name" type="text" id="name" size="30" title="Nom"/>
                                    <input name="email" type="text" id="email" size="30" title="Email"/>
                                    <textarea name="comments" cols="40" rows="3" id="comments"
                                              title="Votre message"></textarea>
                                    <div class="input-wrapper clearfix">
                                        <input type="submit" class="send-btn" value="Send" id="submit" name="Submit"/>
                                        <span id="message"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end #container -->
                </div>
                <!-- end .container -->
            </div>
            <!-- end content -->
        </div>
        <!-- end content-wrapper -->
    </div>
</template>

<script>

export default {
    name: 'Home',
    props: {
        environment: String
    },
}
</script>

