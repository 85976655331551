<template>
  <!-- Preloader -->
  <div id="preloader">
    <div class="canvas-holder">
      <canvas id="preloaderCanvas"></canvas>
    </div>
    <div id="status">
      <div class="parent">
        <div class="child">
          <p class="small">loading</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Preloader',
}
</script>
